import React, { useState } from 'react';
import { Header, HeaderContainer, HeaderName } from '@carbon/react';
import { Link, useSearchParams } from 'react-router-dom';
import logoCoperia from 'layout/navbar/horz-parafondosclaros.png';



export default function Navbar() {
  const [searchParams] = useSearchParams();
  const [mobileLayout] = useState(searchParams.get('mobileLayout') === "true");

  return (
    !mobileLayout &&
    <HeaderContainer
      render={() => (
        <Header aria-label="COPERIA">
          <HeaderName element={Link} to="/" prefix="">
            <img src={logoCoperia} alt="Logo COPERIA" className="coperia-logo"></img>
          </HeaderName>
        </Header>
      )}
    />
  );
}