import { Accordion, AccordionItem } from '@carbon/react';
import React from 'react';
import parse from 'html-react-parser';
import './_foldable_text.scss';

export default function FoldableText({ title, text }) {

  return (
    <div className='generic-content foldable-text'>
      <Accordion>
        <AccordionItem title={title}>{parse(text)}</AccordionItem>
      </Accordion>
  </div>
  );
}