import React from 'react';
import './_youtube.scss';

export default function Youtube({ data }) {
  return (
    <div className={ data.containerCssClass } >
      <iframe src={data.path} 
        title={ data.title || 'YouTube video player'} 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullscreen></iframe>
    </div>
  );
}