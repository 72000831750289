import React from 'react';
import parse from 'html-react-parser';
import './_text.scss';


export default function Text({ data }) {
  
  return (
    <div className={`generic-content ${ data.containerCssClass || 'content-text'}`} >
      { parse(data.text) }
    </div>
  );
}