import React from 'react';

export default function Image({ image }) {
  return (
    <div className='generic-content image'>
      <img src={image} alt={""} />
    </div>
  );
}

