import React from 'react';
import './_video.scss';

export default function Video({ video }) {
  return (
    <div className='generic-content video'>
      <video src={video} controls className='video'>
      </video>
    </div>
  );
}