import React from 'react';
import { Content, GlobalTheme } from '@carbon/react';
import { Route, Routes } from 'react-router';
import Home from 'home/Home';
import 'app.scss';
import Navbar from 'layout/navbar';

function App() {
  return (
    <GlobalTheme theme="white">
      <Navbar />
      <Content>
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path=":id" element={<Home />} />
        </Routes>
      </Content>
    </GlobalTheme>
  );
}

export default App;
