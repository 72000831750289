import { ClickableTile } from '@carbon/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import './_tile.scss';

export default function Tile({ title, description, image, content }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [mobileLayout] = useState(searchParams.get('mobileLayout') === "true");

  return (
    <div className='generic-content tile'>
      <ClickableTile onClick={() => {
        navigate({
          pathname: content,
          search: createSearchParams({
            mobileLayout: mobileLayout
          }).toString()
        });
      }}>
        <img src={image} alt={title} className="tile-image" />
        <div className="tile-title">{title}</div>
        <div className="tile-description">{description}</div>
      </ClickableTile>
    </div>
  );
}