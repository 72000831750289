import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router';
import { Button, Column, Grid } from '@carbon/react';
import GenericContentComponent from './GenericContentComponent';
import { CONTENT, INITIAL_MENU, JSON } from './CommonRoutes';

export default function Home() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [returnEnable, setReturnEnable] = useState(false);
  const [content, setContent] = useState();

  const loadContent = useCallback(async (path) => {
    path === INITIAL_MENU ? setReturnEnable(false) : setReturnEnable(true);
    fetch(path)
      .then((response) => response.json())
      .then((content) => {
        setContent(content);
      }).catch(() => {
        loadContent(INITIAL_MENU);
      });
  }, []);

  useEffect(() => {
    loadContent(id ? CONTENT + id + JSON : INITIAL_MENU);
  }, [id, loadContent]);

  return (
    <div>
      <>
        {content &&
          <Grid>
            <Column lg={6} md={5} sm={4}>
              <GenericContentComponent resource={content}> </GenericContentComponent>
              {returnEnable && <Button onClick={() => navigate(-1)}>Volver</Button>}
            </Column>
          </Grid>
        }
      </>
    </div>

  );

}