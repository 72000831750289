import React from 'react';
import { FOLDABLE, HEADER, IMAGE, MENU, TEXT, TITLE, VIDEO, YOUTUBE } from './ContentType';
import FoldableText from 'components/FoldableText/FoldableText';
import Tile from 'components/Tile/Tile';
import Header from 'components/Header/Header';
import Video from 'components/Video/Video';
import Title from 'components/Title/Title';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import Youtube from 'components/Youtube/Youtube';
import NotFound from 'not-found/NotFound';


export default function GenericContentComponent({ resource }) {

  const returnContent = (entry) => {
    let returnedComponent;
    switch (entry.type) {
      case MENU:
        returnedComponent = <Tile key={entry.order} title={entry.title} description={entry.description} image={entry.html} content={entry.path}></Tile>;
        break;
      case HEADER:
        returnedComponent = <Header key={entry.order} title={entry.title} subtitle={entry.subtitle} image={entry.html}></Header>;
        break;
      case TEXT:
        returnedComponent = <Text key={entry.order} data={entry} />
        break;
      case FOLDABLE:
        returnedComponent = <FoldableText key={entry.order} title={entry.title} text={entry.text}></FoldableText>;
        break;
      case TITLE:
        returnedComponent = <Title key={entry.order} title={entry.title} subtitle={entry.subtitle} ></Title>;
        break;
      case IMAGE:
        returnedComponent = <Image key={entry.order} image={entry.path}></Image>;
        break;
      case VIDEO:
        returnedComponent = <Video key={entry.order} video={entry.path}></Video>;
        break;
      case YOUTUBE:
        returnedComponent = <Youtube key={entry.order} data={entry} />;
        break;
      default:
        returnedComponent = <NotFound />;
    }
    return returnedComponent;
  };

  return resource?.entries.sort((a, b) => a.order-b.order).map(entry => returnContent(entry));
}