import React from 'react';
import './_title.scss';


export default function Title({ title, subtitle }) {
  return (
    <div className='generic-content title' >
      <div className="title-title">{title}</div>
      <div className="title-subtitle">{subtitle}</div>
    </div>
  );
}