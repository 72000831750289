import Title from 'components/Title/Title';
import React from 'react';
import './_header.scss';

export default function Header({ title, subtitle, image }) {

  return (<>
    <div className='generic-content header'>
      <img src={image} alt={title} className="header-image" />
      <Title title={title} subtitle={subtitle} ></Title>
    </div>
  </>
  );
}